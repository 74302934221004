const cronToText = (cronExpression) => {
  // Validate input
  if (!cronExpression || typeof cronExpression !== 'string') {
    return 'Invalid cron expression';
  }

  const cronParts = cronExpression.trim().split(/\s+/);
  if (cronParts.length !== 5) {
    return 'Invalid cron expression. Example: "0 12 * * 1"';
  }

  const [minute, hour, dayOfMonth, month, dayOfWeek] = cronParts;

  // Constants for mapping
  const DAYS_OF_WEEK = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday'
  };

  const MONTHS = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December'
  };

  // Helper function to validate number within range
  const isValidNumber = (num, min, max) => {
    const parsed = parseInt(num, 10);
    return !Number.isNaN(parsed) && parsed >= min && parsed <= max;
  };

  // Helper function for ordinal suffixes
  const getOrdinalSuffix = (num) => {
    const parsedNum = parseInt(num, 10);
    if (!isValidNumber(parsedNum, 1, 31)) return null;

    const lastDigit = parsedNum % 10;
    const lastTwoDigits = parsedNum % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
      return `${parsedNum}th`;
    }

    switch (lastDigit) {
      case 1:
        return `${parsedNum}st`;
      case 2:
        return `${parsedNum}nd`;
      case 3:
        return `${parsedNum}rd`;
      default:
        return `${parsedNum}th`;
    }
  };

  // Parse time
  const parseTime = () => {
    const validMinute = isValidNumber(minute, 0, 59);
    const validHour = isValidNumber(hour, 0, 23);

    if (minute === '*' && hour === '*') return 'every minute';
    if (minute === '*')
      return `every minute of ${validHour ? `${hour.padStart(2, '0')}:00` : 'hour'}`;
    if (hour === '*') return `at minute ${minute.padStart(2, '0')} of every hour`;

    if (!validMinute || !validHour) return 'at invalid time';
    return `at ${hour.padStart(2, '0')}:${minute.padStart(2, '0')}`;
  };

  // Parse day of week
  const parseDayOfWeek = () => {
    if (dayOfWeek === '*') return 'every day';

    if (dayOfWeek.includes(',')) {
      const days = dayOfWeek
        .split(',')
        .filter((day) => DAYS_OF_WEEK[day])
        .map((day) => DAYS_OF_WEEK[day]);

      if (days.length === 0) return 'on invalid day';
      return `on ${days.join(' and ')}`;
    }

    return DAYS_OF_WEEK[dayOfWeek] ? `on ${DAYS_OF_WEEK[dayOfWeek]}` : 'on invalid day';
  };

  // Parse day of month
  const parseDayOfMonth = () => {
    if (dayOfMonth === '*') return 'every day';

    const ordinalDay = getOrdinalSuffix(dayOfMonth);
    if (!ordinalDay) return 'on invalid day of month';

    return `on the ${ordinalDay}`;
  };

  // Parse month
  const parseMonth = () => {
    if (month === '*') return 'every month';

    if (month.includes(',')) {
      const months = month
        .split(',')
        .filter((m) => MONTHS[m])
        .map((m) => MONTHS[m]);

      if (months.length === 0) return 'in invalid month';
      return `in ${months.join(' and ')}`;
    }

    return MONTHS[month] ? `in ${MONTHS[month]}` : 'in invalid month';
  };

  // Build the complete description
  const parts = [];
  parts.push(parseTime());

  if (dayOfMonth !== '*') {
    parts.push(parseDayOfMonth());
  }

  if (month !== '*') {
    parts.push(parseMonth());
  }

  if (dayOfWeek !== '*') {
    parts.push(parseDayOfWeek());
  }

  return parts.join(', ');
};

export default cronToText;
